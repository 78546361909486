<template>
  <div class="libraryS">
    <div class="box">
      <div class="left">
        <div class="dataAnalysisDetails" v-show="isDetails">
          <dataAnalysisDetails
            ref="dataAnalysisDetails"
            @backClick="backClick"
          />
        </div>
        <!-- 占位 -->
        <div class="perch" v-show="!isDetails"></div>

        <!-- 遮罩层 -->
        <div
          class="mask_layer"
          v-if="ChildrenInfoShow"
          @click="ChildrenInfoShow = !ChildrenInfoShow"
        ></div>

        <div class="sidebar_box" v-show="!isDetails">
          <div
            class="an_btn"
            :class="ChildrenInfoShow ? 'spin' : ''"
            v-show="ChildrenInfo.list.length != 0"
            @click="
              () => {
                ChildrenInfoShow = !ChildrenInfoShow;

                if (ChildrenInfoShow) {
                  if (ChildrenInfo.list.length != 0) {
                    ChildrenInfo.isShow = true;
                  } else if (ChildrenInfo1.list.length != 0) {
                    ChildrenInfo1.isShow = true;
                  } else if (ChildrenInfo2.list.length != 0) {
                    ChildrenInfo2.isShow = true;
                  }
                }
              }
            "
          >
            <i class="el-icon-arrow-right"></i>
          </div>

          <!-- 第一级 -->
          <div class="sidebar">
            <el-menu
              ref="elMenu"
              :collapse-transition="false"
              :unique-opened="false"
            >
              <el-menu-item index="-2" @click="handleAllClick">
                全部
              </el-menu-item>
              <el-menu-item index="-1" @click="handleSCClick">
                收藏文章
              </el-menu-item>

              <el-submenu
                :hide-timeout="0"
                :show-timeout="0"
                :index="item.labelId"
                v-for="(item, index) in btnArr"
                @click.native="
                  () => {
                    searchValue = '';
                    $refs.elMenu.activeIndex = null;
                    sidebarTtemId = item.labelId;
                    pageNum = 1;
                    isCollect = false;
                    noText = '更新中';
                    ChildrenInfoShow = false;
                    ChildrenInfo.isShow = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    stair = item.labelId;
                    currentClickItemVip = item;
                    currentSidebar = '';
                    checked = false;
                    checkarr = [];
                    findChild(item);
                  }
                "
                :class="[
                  sidebarTtemId == item.labelId ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 9 ? item.conName : ''"
              >
                <template slot="title">
                  {{ item.conName }}
                  <el-tooltip
                    effect="light"
                    :content="item.remark"
                    placement="right"
                    popper-class="custom-popper"
                  >
                    <img
                      v-if="item.isVip != 1"
                      class="vipIcon"
                      :src="
                        item.isVip == 2
                          ? require('assets/images/headers/vip.png')
                          : item.isVip == 3
                          ? require('assets/images/headers/svip.png')
                          : require('assets/images/headers/plus.png')
                      "
                      @click="memberRenewal(item)"
                      alt=""
                  /></el-tooltip>
                  <img
                    v-if="item.labelTip != 0 && item.labelTip != null"
                    :src="
                      item.labelTip == 1
                        ? require('assets/images/hot1.png')
                        : item.labelTip == 2
                        ? require('assets/images/j.png')
                        : require('assets/images/new1.png')
                    "
                    alt=""
                  />
                </template>
                <el-menu-item
                  :index="index + '-' + index1"
                  v-for="(item1, index1) in item.children"
                  :key="item1.id"
                  :title="item1.conName.length > 8 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      parentName = item1.conName;
                      themeI = -1;
                      sidebarTtemId = -1;
                      checked = false;
                      pageNum = 1;
                      isCollect = false;
                      if (ChildrenInfo.list.length != 0) {
                        if (item1.isSubset) {
                          ChildrenInfo.isShow = true;
                        }
                      }
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getClicktheme(item1, 1);
                    }
                  "
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                >
                  <!-- <img class="img1" :src="$store.state.onlineBasePath + item1.iconImg" alt="" /> -->
                  {{ item1.conName
                  }}<img
                    v-if="item1.checked == 2"
                    class="img2"
                    :src="require('assets/images/gouwuche1.png')"
                    @click="shoppCar(item1.productId)"
                    alt=""
                  />
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第二级 -->
          <div
            class="sidebar"
            :class="!ChildrenInfoShow ? 'absolute' : ''"
            v-show="ChildrenInfo.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="false"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo.isShow = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo.list"
                @click.native="
                  () => {
                    checked = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    getClicktheme(item, 2);
                  }
                "
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 7 ? item.conName : ''"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      checked = false;
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getClicktheme(item1, 3);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第三级 -->
          <div
            class="sidebar"
            :class="!ChildrenInfoShow ? 'absolute' : ''"
            v-show="ChildrenInfo1.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="false"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo1.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo1.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                @click.native="
                  () => {
                    checked = false;
                    ChildrenInfo2.isShow = false;
                    getClicktheme(item, 2);
                  }
                "
                :key="item.id"
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      checked = false;
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getClicktheme(item1, 4);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第四级 -->
          <div
            class="sidebar"
            :class="!ChildrenInfoShow ? 'absolute' : ''"
            v-show="ChildrenInfo2.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="false"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo2.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo2.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                @click.native="
                  () => {
                    checked = false;
                    getClicktheme(item, 2);
                  }
                "
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      checked = false;
                      getClicktheme(item1, 5);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>

        <div class="infocompileIndex" id="infocompileIndex" v-show="!isDetails">
          <div class="banner" v-if="false">
            <div class="item" @click="goMyWork">
              <img :src="require('assets/images/cupload1.png')" alt="" />
              版权作品投稿
            </div>
            <div class="item" @click="goWorksRank">
              <img :src="require('assets/images/rank1.png')" alt="" />
              作品排名
            </div>
            <div class="item" @click="goAuthorRank">
              <img :src="require('assets/images/jiangb1.png')" alt="" />
              作者排名
            </div>
            <div class="item" @click="goMyAttention">
              <img :src="require('assets/images/author_f1.png')" alt="" />
              关注作者
            </div>
          </div>
          <div class="search_box">
            <div class="search">
              <div class="search_left">
                <el-select
                  @change="selectChange"
                  v-model="selectTheme"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <input
                class="inp"
                v-model="searchValue"
                type="text"
                placeholder="让每个人都写得更好"
                @keyup.enter="search"
              />
              <div class="search_btn" @click="search">
                <!-- <i class="search_icon"></i> -->
                <img :src="require('assets/images/idiom.png')" alt="" />
                <img
                  :src="require('assets/images/included_search.png')"
                  alt=""
                />
              </div>
            </div>

            <!-- <div class="keyword_box">
          <div
            class="keyword_item"
            v-for="item in keyword"
            :key="item"
            @click="navInfoCompile(item)"
          >
            {{ item }}
          </div>
        </div> -->
          </div>

          <div class="infocompileIndex-content">
            <div
              class="infocompileIndex-content-breadcrumb"
              v-if="breadcrumb.length != 0"
            >
              <div
                class="infocompileIndex-content-breadcrumb-item"
                v-for="(item, index) in breadcrumb"
                :key="index"
              >
                <el-tooltip
                  class="item"
                  :content="item.title"
                  effect="light"
                  placement="bottom"
                >
                  <span @click="closeWord(item)" class="item-title">
                    <el-button :class="item.title == itemflag ? 'color' : ''">{{
                      item.title
                    }}</el-button>
                  </span>
                </el-tooltip>
                <span
                  @click="closeWord(item)"
                  class="item-num"
                  :class="item.title == itemflag ? 'color' : ''"
                  >{{ item.num }}</span
                >
                <i @click="close(index)" class="el-icon-close"></i>
              </div>
            </div>

            <div class="infocompileIndex-content-list">
              <div class="infocompileIndex-content-list-title">
                <el-checkbox
                  v-show="selectTheme != '5' && selectTheme != '6'"
                  v-model="checked"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
                <div
                  class="infocompileIndex-content-list-title-right"
                  v-show="!isCollect"
                >
                  <div
                    class="right-item"
                    v-for="(item, index) in sortArr"
                    :key="index"
                    @click="handleClick(item)"
                  >
                    <div
                      class="right-item-text"
                      :class="item.colorSwitch == true ? 'fontColor' : ''"
                    >
                      {{ item.title }}
                    </div>
                    <i
                      class="right-item-icon"
                      :class="item.sort == true ? 'up' : 'down'"
                    ></i>
                  </div>
                </div>
                <div
                  v-show="selectTheme != '5' && selectTheme != '6'"
                  class="infocompileIndex-content-list-title-left"
                >
                  <div class="left-item" @click="addAssembly">
                    <i class="left-item-icon add"></i>
                    <div class="left-item-text">添加汇编</div>
                  </div>
                  <div class="left-item" @click="showAbn">
                    <i class="left-item-icon icon"></i>
                    <el-badge :value="assemblyNum" :max="99">
                      <div class="left-item-text">汇编成册</div>
                    </el-badge>
                  </div>
                  <el-upload
                    class="upload-demo"
                    action=""
                    :show-file-list="false"
                    :http-request="importChecking"
                    style="margin-left: 9px"
                  >
                    <div class="left-item" v-show="false">
                      <i class="left-item-icon download"></i>
                      <div class="left-item-text">上传文件</div>
                    </div>
                  </el-upload>
                  <div class="left-item" v-show="false" @click="goDisk">
                    <i class="left-item-icon folder"></i>
                    <div class="left-item-text">打开文件夹</div>
                  </div>
                  <!-- <div class="left-item" @click="batchDownload">
                <i class="left-item-icon download"></i>
                <div class="left-item-text">批量下载</div>
              </div> -->
                </div>
              </div>
              <div class="infocompileIndex-content-list-content">
                <div v-for="(v, i) in infoList" :key="i" class="content-item">
                  <!-- 转载或原创图标 -->
                  <!-- <i v-if="v.firstLaunch == 1" class="content-item-icon reprint"></i>
						<i v-if="v.firstLaunch == 2" class="content-item-icon starting"></i> -->
                  <!-- 收藏图标 -->
                  <el-tooltip
                    effect="light"
                    placement="left"
                    popper-class="origina"
                  >
                    <div slot="content">
                      <div style="white-space: nowrap">
                        溯源版原创率{{ v.traceSourceEvaluation || 0 }}%
                      </div>
                      <div style="white-space: nowrap">
                        溯源时间:{{ v.traceSourceDate || "2022-01-01" }}
                      </div>
                    </div>
                    <div
                      v-show="v.isTraceSource"
                      class="source_version"
                      @click="findSourceVersion(v)"
                    >
                      <img
                        :src="require('assets/images/cy_source.png')"
                        alt=""
                      />
                      <span>溯源版({{ v.traceSourceEvaluation || 0 }}%)</span>
                    </div>
                  </el-tooltip>
                  <div class="title">
                    <div
                      v-show="selectTheme != '5' && selectTheme != '6'"
                      class="introduce-checkBox"
                      @click="handleChecked(v, i)"
                    >
                      <i
                        class="introduce-checkBox-icon"
                        :class="v.ischecked == true ? 'checkBox-checked' : ''"
                      ></i>
                      <!-- <div class="introduce-checkBox-text">标准版</div> -->
                    </div>
                    <div class="title_box" @click="clickPreview(v)">
                      <span v-if="v.isAssembly">(已添加)</span>
                      {{ v.footNotes }}
                    </div>
                  </div>

                  <div class="content-item-text">
                    <span v-html="v.nodeText"></span>
                    <!-- <span @click="handleAllText(v)">查看全文</span> -->
                  </div>

                  <div
                    class="content-item-introduce"
                    v-show="selectTheme != '5' && selectTheme != '6'"
                  >
                    <!-- <div class="introduce-radioBox">
                <i
                  class="introduce-radioBox-icon"  :class="v.traceSource == 1 ?'radioBox-checked':''"
                ></i>
                <div class="introduce-radioBox-text">溯源版</div>
              </div> -->
                    <i
                      class="content-item-like"
                      :class="v.isMark == true ? 'like-red' : 'like'"
                      @click="collect(v)"
                    ></i>
                    <div
                      class="content-item-btnGroup"
                      v-show="selectTheme != '5' && selectTheme != '6'"
                    >
                      <div
                        class="content-item-btnItem downloadBox"
                        @click="singleDownload(v, 1)"
                      >
                        <i class="content-item-btnItem-icon download"></i>
                        <div class="content-item-btnItem-text">下载</div>
                      </div>

                      <!-- <div
                        class="content-item-btnItem uploadBox"
                        @click="moveTo($event, v)"
                      >
                        <i class="content-item-btnItem-icon upload"></i>
                        <div class="content-item-btnItem-text">转存</div>
                      </div> -->
                      <!-- <div class="btnGroup-line"></div> -->
                      <!-- <div class="content-item-btnItem relationBox">
                  <i class="content-item-btnItem-icon relation"></i>
                  <div class="content-item-btnItem-text">关联内容</div>
                </div>
                <div class="btnGroup-line"></div>
                <div class="content-item-btnItem indexBox">
                  <i class="content-item-btnItem-icon index"></i>
                  <div class="content-item-btnItem-text">索引号</div>
                </div> -->
                      <!-- <div class="btnGroup-line"></div>
                <div class="content-item-btnItem copyrightBox">
                  <i v-if="v.claim == 1" class="content-item-btnItem-icon copyright"></i>
                  <div v-if="v.claim == 1" class="content-item-btnItem-text">待认领</div>
                  <img v-if="v.claim == 2" :src="require('assets/images/lanbanquan.png')" />
                  <div v-if="v.claim == 2" class="content-item-btnItem-text">已认领</div>
                </div> -->

                      <div
                        class="content-item-btnItem shareBox"
                        @click="shareClick(v)"
                      >
                        <i class="content-item-btnItem-icon share"></i>
                        <div class="content-item-btnItem-text">分享</div>
                      </div>
                    </div>
                    <div class="introduce-starBox">
                      <i class="introduce-starBox-icon"></i>
                      <div
                        class="introduce-starBox-text"
                        v-text="v.authoritative"
                      ></div>
                    </div>
                    <!-- <div class="introduce-hotBox">
                  <i class="introduce-hotBox-icon"></i>
                  <div class="introduce-hotBox-text" v-text="v.hot"></div>
                </div> -->
                    <div class="introduce-lampBox">
                      <i class="introduce-lampBox-icon"></i>
                      <div
                        class="introduce-lampBox-text"
                        v-text="v.origin"
                      ></div>
                    </div>
                    <div class="introduce-timeBox">
                      <i class="introduce-timeBox-icon"></i>
                      <div
                        class="introduce-timeBox-text"
                        v-text="v.publishTime"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="flag" class="kong">
                <img :src="require('assets/images/kong.png')" />
                <div>{{ noText }}</div>
              </div>
            </div>
          </div>
          <div v-if="!flag" class="infocompileIndex-footer">
            <el-pagination
              v-if="infoList.length != 0"
              :layout="
                selectTheme == '5' || selectTheme == '6'
                  ? 'prev, pager, next'
                  : 'prev, pager, next'
              "
              :pager-count="5"
              :current-page="pageNum"
              :page-size="10"
              @current-change="currentChange"
              :total="
                selectTheme == '5' || selectTheme == '6'
                  ? searchValue
                    ? totalNum
                    : 100
                  : totalNum
              "
            >
            </el-pagination>
          </div>
          <!-- 子菜单弹窗 -->
          <div v-if="menuflag" class="infoMian">
            <div class="title">信息库树</div>
            <div class="xian"></div>
            <div @click="infoCancel" class="cancel">x</div>
            <el-tree
              class="eltree"
              highlight-current
              :props="props"
              :load="loadNode"
              @node-click="nodeClick"
              @node-expand="nodeExpand"
              lazy
            >
            </el-tree>
          </div>

          <!-- 转存网盘 -->
          <el-dialog title="转存网盘" :visible.sync="movePopup" class="addFil">
            <el-tree
              :expand-on-click-node="false"
              style="180px"
              class="tree filter-tree"
              highlight-current
              accordion
              :indent="16"
              :data="TreeData"
              :auto-expand-parent="false"
              node-key="id"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                  {{ test(node, data) }}
                  <el-image
                    :src="
                      require(`@/assets/images/disk/Icons/${data.picture}.png`)
                    "
                    style="width: 30px; height: 30px; vertical-align: middle"
                  ></el-image>
                  {{ data.filename }}
                </span>
              </span>
            </el-tree>
            <span slot="footer" class="dialog-footer">
              <button class="footer-btn sure" @click="handleSure">确 定</button>
              <button class="footer-btn" @click="movePopup = false">
                取 消
              </button>
            </span>
          </el-dialog>

          <!-- 汇编成册 -->
          <el-dialog
            :modal="false"
            :close-on-click-modal="false"
            :visible.sync="compileVolumeDialog"
            title="汇编成册"
            class="compile-volume"
          >
            <div class="content">
              <div class="content-text">自动生成含目录的资料汇编</div>
            </div>
            <div class="footer">
              <div class="footer-btn" @click="handleCompileVolume">确定</div>
              <!-- <div class="footer-btn clear" @click="cleanCompile">清空汇编</div> -->
              <div class="footer-btn" @click="compileVolumeDialog = false">
                取消
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
      <div class="right recommendRightList">
        <recommend :showMore="'showMore'" />
      </div>
    </div>

    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
      </div>
    </el-dialog>

    <el-dialog
      v-dialogDrag
      title="上传成功"
      center
      :visible.sync="uploadSuccess"
    >
      <div style="text-align: center">
        文件已经保存在 <span style="color: #409eff">智能网盘</span>下的
        <span style="color: #409eff">系统文件/收藏文档/收藏文章</span
        >中,可随时去查看
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goDisk">现在查看</el-button>
        <el-button @click="uploadSuccess = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 汇编记录 -->
    <el-dialog
      title="汇编记录"
      class="compilations"
      :visible.sync="compilations"
      :close-on-click-modal="false"
      width="800px"
    >
      <div class="progress" :style="{ width: progress + '%' }"></div>
      <div class="option">
        <el-badge :value="assemblyNum" class="item">
          <el-button size="small" @click="addCompileVolume">汇编成册</el-button>
        </el-badge>
        <el-button
          size="small"
          style="margin-left: 20px"
          @click="
            showFileList = true;
            initSelectList();
            selectPage = 1;
          "
          >文件列表</el-button
        >
      </div>

      <record ref="record" />
    </el-dialog>
    <el-dialog
      title="文件列表"
      :modal="true"
      :visible.sync="showFileList"
      class="selectList"
      width="800px"
    >
      <el-button size="small" @click="deleteSelectAll">删除选中</el-button>
      <el-button size="small" @click="cleanCompile">清空汇编</el-button>
      <el-table
        @select="selectRow"
        @select-all="selectRow"
        v-loading="dialogLoading"
        :data="selectList"
      >
        <el-table-column
          width="50"
          type="selection"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="footNotes"
          min-width="600"
          align="center"
          label="标题"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <img
                :src="require('assets/images/delete-blue.png')"
                style="vertical-align: middle; cursor: pointer"
                @click="deleteSelectFile(scope.row)"
                alt=""
              />
              <span
                style="color: #4587ff; cursor: pointer"
                @click="deleteSelectFile(scope.row)"
                >删除</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: center"
        @current-change="selectCurrentChange"
        :current-page="selectPage"
        :page-size="10"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="selectTotal"
      >
      </el-pagination>
    </el-dialog>

    <idiomTips
      @closeClick="isShow = false"
      @paySuccess="paySuccess"
      ref="idiomTips"
      @NoCoupon="NoCoupon"
      :Tipsmessage="Tipsmessage"
      :vouchersNum="vouchersNum"
      :isShow="isShow"
      :dataType="dataType"
    />
  </div>
</template>

<script>
import idiomTips from "components/idiomTips";
import dataAnalysisDetails from "../dataAnalysis/dataAnalysisDetails.vue";
import recommend from "components/recommend/recommend.vue";
import record from "../infoCompile/record";
import { getToken } from "@/utils/auth";
import { debounce } from "utils/utils";
import { settings } from "api/service";
import { getlibrarylist } from "api/dropDownList";
import {
  list,
  queryOfficicalWord,
  addAssemblys,
  getAssemblyNums,
  fileDownload,
  saveNetdisk,
  Collection,
  writerMergeWord,
  delDocTempInfo,
  findTextUrl,
  analysisSearch,
  saveToCollect,
  querylCollectionWord,
  openLibraryFile,
  openLibraryTraceSourceFile,
  checkAssembly,
  queryAssembleWord,
  delAssembleByIds,
} from "api/infoCompile";
import { directory } from "api/disk";
import QRCode from "qrcodejs2";
import qs from "qs";
export default {
  name: "libraryS",
  data() {
    return {
      Tipsmessage: "",
      itemInfo: "",
      vouchersNum: 1,
      isShow: false,
      dataType: 4,
      showFileList: false,
      selectPage: 1,
      selectTotal: 0,
      openList: [],
      ChildrenInfoShow: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      isAn: false,
      compilations: false,
      parentName: "",
      currentClickItemVip: null,
      sidebarTtemId: -1,
      QrCodeVisible: false,
      qr: "",
      noText: "暂无数据",
      isCollect: false,
      sendKey: this.$route.query.key,
      btnArr: [],
      currentSidebar: "全部",
      options: [
        {
          value: "1",
          label: "全文",
        },
        {
          value: "2",
          label: "标题",
        },
        // {
        //   value: "5",
        //   label: "标准段",
        // },
        // {
        //   value: "6",
        //   label: "标准句",
        // },
        {
          value: "3",
          label: "作者",
        },
        {
          value: "4",
          label: "来源",
        },
      ],
      stair: "WKlingdrssx00001",
      selectTheme: "1",
      value: "选项1",
      // 搜索关键字
      searchValue: "",
      breadcrumb: [],
      checked: [],
      //选中的数组
      checkarr: [],
      sortArr: [
        {
          title: "时间",
          sort: false,
          colorSwitch: true,
        },
        {
          title: "权威",
          sort: true,
          colorSwitch: false,
        },
        // {
        //   title: "热度",
        //   sort: true,
        //   colorSwitch: false,
        // },
      ],
      pageCount: 10,
      tableSelectList: [],
      dialogLoading: false,
      selectList: [],
      pageNum: 1,
      //信息列表
      infoList: [],
      //分页密钥
      pageUuid: "",
      assemblyNum: null,
      progress: 0,
      timer: null,
      isPlaying: 0,
      progressTimer: null,
      //信息排序
      sort: "desc",
      //排序类型
      sortType: "time_sort",
      //无数据时显示
      flag: false,
      //信息菜单
      infoflag: false,
      //子菜单弹窗
      menuflag: false,
      //子菜单信息
      data: [],
      //二级菜单信息
      second: [],
      //三级菜单信息
      tertiary: [],
      //四级菜单
      four: [],
      //五级菜单
      five: [],
      //六级菜单
      six: [],
      //名称显示
      props: {
        label: "name",
        isLeaf: "leaf",
      },
      //搜索类型
      findField: 1,
      //菜单id
      meunIds: 100,
      //常选词
      itemflag: "",
      uploadSuccess: false,
      //转存网盘弹窗
      movePopup: false,
      checkData: "",
      TreeData: [],
      moveid: "",
      md5: "",
      defaultProps: {
        children: "children",
        label: "filename",
      },
      //添加常用词数量
      totalNum: "",
      //汇编成册弹窗
      compileVolumeDialog: false,
      labelId: "WKlingdrssx00001",
      // 子级
      childrenList: [],
      isDetails: false,
      stairCopy: "",
    };
  },
  watch: {
    sendKey(val) {
      this.key = val;

      this.getList(this.labelId);
    },
  },
  components: {
    dataAnalysisDetails,
    recommend,
    idiomTips,
    record,
  },
  methods: {
    paySuccess() {
      this.isShow = false;
      if (this.dataType == 4) {
        this.handleCompileVolume();
      } else {
        this.singleDownload(this.itemInfo, 2);
      }
      // this.$message.success("快捷支付成语券成功");
    },
    NoCoupon() {
      this.isShow = true;
    },
    // 关闭去除二维码
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = "";
    },
    shareClick(val) {
      this.QrCodeVisible = true;
      this.$nextTick(() => {
        this.qr = new QRCode(this.$refs.qrcode_box, {
          text: `https://mall.writemall.com/infoCompile?textMd5=${
            val.textMd5
          }&labelId=${this.stair ? this.stair : this.labelId}&originalId=${
            val.userId
          }`,
          width: 130,
          height: 130,
          correctLevel: 3,
          colorDark: "#000000",
          colorLight: "#ffffff",
        });
      });
    },
    async collectlist() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const data = {
        page: this.pageNum,
        limit: 10,
      };

      const res = await querylCollectionWord(data);

      if (res.code == 200) {
        this.flag = false;
        this.infoList = res.data.data;
        this.totalNum = res.data.total;

        this.pageCount = Math.ceil(res.data.total / 11);
        if (res.data.data.length == 0) {
          this.flag = true;
        }

        // 手动添加 ischecked判断是否选中
        this.infoList.map((item) => {
          item.ischecked = item.isAssembly;
        });
      } else {
        this.$message.error(res.message);
      }
    },
    selectChange(val) {
      this.pageNum = 1;
      this.pageUuid = "";
      this.getList(this.labelId);
    },
    backClick() {
      this.isDetails = false;
    },
    handleAllClick() {
      this.$refs["elMenu"].close(this.stair);
      this.isCollect = false;
      this.pageUuid = "";
      this.pageNum = 1;
      this.sidebarTtemId = "";
      this.labelId = "";
      this.stair = "WKlingdrssx00001";
      this.stairCopy = "";
      this.getList();
      this.getMenuList();
      this.noText = "更新中";
      this.currentSidebar = "全部";
      this.checked = false;
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
      this.checkarr = [];
    },
    handleSCClick() {
      this.$refs["elMenu"].close(this.stair);
      this.isCollect = true;
      this.pageNum = 1;
      this.sidebarTtemId = "";
      this.currentSidebar = "收藏文章";
      this.noText = "暂无收藏";
      this.checked = false;
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
      this.checkarr = [];
      this.collectlist();
    },
    async findSourceVersion(item) {
      if (this.labelId == null || this.labelId == "undefined") {
        this.labelId = "WKlingdrssx00001";
      }
      const data = {
        md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
        originalId: item.userId,
        templateId: "",
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      try {
        const resdata = await openLibraryTraceSourceFile(qs.stringify(data));
        loading.close();
        // if (!resdata.data.status) {
        this.$refs["dataAnalysisDetails"].openLibraryFile = {
          ...resdata.data.obj,
          html: resdata.data.html,
          payStatus: resdata.data.status,
          url: resdata.data.url,
          viptip: resdata.data.viptip,
          // author: resdata.data.author,
          originalId: item.userId,
          md5Label:
            item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
          traceSourceDate: item.traceSourceDate,
          traceSourceEvaluation: item.traceSourceEvaluation,
          isTraceSource: item.isTraceSource,
          currentClickItemVip: this.currentClickItemVip,
        };
        window.document.documentElement.scrollTop = 0;
        this.$refs["dataAnalysisDetails"].openLibraryFile.author =
          resdata.data.author;
        this.isDetails = true;
        // } else {

        // }
      } catch (e) {
        loading.close();
      }
    },
    async clickPreview(item) {
      if (this.labelId == null || this.labelId == "undefined") {
        this.labelId = "WKlingdrssx00001";
      }

      const data = {
        md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
        originalId: item.userId,
        templateId: "",
      };

      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });

      const resdata = await openLibraryFile(qs.stringify(data));
      loading.close();

      if (resdata.code != 200) {
        this.$message.error(resdata.message);
        return;
      }
      this.$refs["dataAnalysisDetails"].openLibraryFile = {
        ...resdata.data.obj,
        html: resdata.data.html,
        payStatus: resdata.data.status,
        url: resdata.data.url,
        viptip: resdata.data.viptip,
        // author: resdata.data.author,
        originalId: item.userId,
        md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
        traceSourceDate: item.traceSourceDate,
        traceSourceEvaluation: item.traceSourceEvaluation,
        isTraceSource: item.isTraceSource,
        currentClickItemVip: this.currentClickItemVip,
      };
      window.document.documentElement.scrollTop = 0;
      this.$refs["dataAnalysisDetails"].openLibraryFile.author =
        resdata.data.author;
      this.isDetails = true;
    },
    //选择常用词
    closeWord(item) {
      this.itemflag = item.title;
      this.pageUuid = "";
      this.key = "";
      this.meunIds = item.id;
      this.getList(this.labelId);
    },
    async findChild(item) {
      console.log(item);
      this.pageUuid = "";
      this.labelId = item.labelId;
      this.getList(item.labelId);
      if (item.children.length != 0) {
        return;
      }

      this.$forceUpdate();
      const data = {
        labelId: item.labelId,
        parentId: 7,
      };

      // let res = await list(qs.stringify(data));
      let res = await getlibrarylist(qs.stringify(data));

      if (res.code == 200) {
        this.$set(item, "children", res.data);
      }
    },
    // 是否收藏
    collect: debounce(async function (item) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      let formData = new FormData();
      formData.append(
        "textMd5",
        item.textMd5 + "/" + (this.stair ? this.stair : this.labelId)
      );
      const res = await Collection(formData);
      if (res.code === 200) {
        if (item.isMark == true) {
          this.$message({
            type: "success",
            message: "取消收藏",
          });
        } else {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        }
        if (this.isCollect) {
          if (this.infoList.length == 1 && this.pageNum != 1) {
            this.pageNum--;
          }
          this.collectlist();
        } else {
          this.getList(this.labelId);
        }
      } else if (res.code == 8) {
        this.$message({
          type: "error",
          message: res.message,
        });
        this.clickPreview(item);
      } else if (res.code == 4) {
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    }),
    //点击获取主题
    async getClicktheme(item, i) {
      this.pageUuid = "";
      this.labelId = item.labelId;
      this.getList(item.labelId);
      const data = {
        labelId: item.labelId,
        parentId: 7,
      };

      if (i == 1) {
        if (item.isSubset) {
          this.ChildrenInfo.isShow = true;
        }
        if (this.ChildrenInfo.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo1.isShow = false;
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo1.list = [];
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo.parentName = item.conName;

        this.ChildrenInfo.labelId = item.labelId;
      } else if (i == 3) {
        if (item.isSubset) {
          this.ChildrenInfo1.isShow = true;
        }
        if (this.ChildrenInfo1.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo1.parentName = item.conName;

        this.ChildrenInfo1.labelId = item.labelId;
      } else if (i == 4) {
        if (item.isSubset) {
          this.ChildrenInfo2.isShow = true;
        }
        if (this.ChildrenInfo2.labelId == item.labelId) {
          return;
        }

        this.ChildrenInfo2.parentName = item.conName;
        this.ChildrenInfo2.labelId = item.labelId;
      }
      if (i == 2) {
        if (item.children.length != 0) {
          return;
        }
      }

      const res = await getlibrarylist(qs.stringify(data));

      if (i == 1) {
        this.ChildrenInfo.list = res.data;
      } else if (i == 3) {
        this.ChildrenInfo1.list = res.data;
      } else if (i == 4) {
        this.ChildrenInfo2.list = res.data;
      }

      if (i == 2) {
        item.children = res.data;
        this.$forceUpdate();
      }
    },

    //添加常用词
    async addword(name, id) {
      if (this.breadcrumb.length == 5) {
        this.breadcrumb.shift();
        for (var i of this.breadcrumb) {
          if (i.title == name) {
            this.itemflag = name;
            return;
          }
        }

        this.breadcrumb.push({
          num: this.totalNum,
          title: name,
          id: id,
        });
      } else {
        for (var a of this.breadcrumb) {
          if (a.title == name) {
            this.itemflag = name;
            return;
          }
        }
        this.breadcrumb.push({
          num: this.totalNum,
          title: name,
          id: id,
        });
      }
    },
    //删除常用词
    close(i) {
      this.breadcrumb.splice(i, 1);
      this.pageUuid = "";
      this.key = "";
      this.meunIds = 100;
      this.getList(this.labelId);
      this.itemflag = "";
    },
    //点击子菜单
    nodeClick(data) {
      this.meunIds = data.id;
      this.pageUuid = "";
      this.key = "";
      this.meunIds = data.id;
      this.getList(this.labelId);
    },
    //展开子菜单
    nodeExpand(data) {
      this.itemflag = data.name;
      this.addword(data.name, data.id);
    },
    //选择搜索类型
    change() {
      if (event.target.innerText == "全文") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 1;
        this.getList(this.labelId);
      }
      if (event.target.innerText == "标题") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 2;
        this.getList(this.labelId);
      }
      if (event.target.innerText == "作者") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 3;
        this.getList(this.labelId);
      }
      if (event.target.innerText == "来源") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 4;
        this.getList(this.labelId);
      }
      if (event.target.innerText == "标准段") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 5;
        this.getList(this.labelId);
      }
      if (event.target.innerText == "标准句") {
        this.pageNum = 1;
        this.pageUuid = "";
        this.findField = 6;
        this.getList(this.labelId);
      }
    },
    //获取分级菜单
    async loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve(this.data);
      }
      if (node.level == 1) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.second = res.data;
          // for(var a of this.second){
          // 	const data = {
          // 		id : a.id
          // 	}
          // 	let res = await list(qs.stringify(data))
          // 	if(res.code ==2001){
          // 		a.leaf = true;
          // 	}
          // }
          return resolve(this.second);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 2) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.tertiary = res.data;
          return resolve(this.tertiary);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 3) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.four = res.data;
          return resolve(this.four);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 4) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.five = res.data;
          return resolve(this.five);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 5) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.six = res.data;
          return resolve(this.six);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
    },
    //显示弹窗
    infoshow() {
      this.menuflag = true;
    },
    //关闭菜单弹窗
    infoCancel() {
      this.menuflag = false;
      this.infoflag = false;
      //this.pageUuid = "";
      //this.key = "";
      //this.meunIds = 100;
      //this.getList();
      //this.itemflag = ''
    },
    //获取子菜单
    // async closeMenu(item) {
    //   this.itemflag = item.name;
    //   let data = {
    //     id: item.id,
    //   };
    //   let res = await list(qs.stringify(data));
    //   this.data = res.data;
    //   if (this.data == null) {
    //     this.infoflag = false;
    //   } else {
    //     this.infoflag = true;
    //   }
    //   this.pageUuid = "";
    //   this.key = "";
    //   this.meunIds = item.id;
    //   this.getList(this.labelId);
    //   this.addword(item.name, item.id);
    // },
    //搜索信息
    search() {
      this.pageNum = 1;
      this.pageUuid = "";
      this.getList(this.labelId);
    },
    // YJhexyjk00001
    //获取信息
    async getList(labelId) {
      console.log(labelId);
      this.labelId = labelId;
      let labelIdCopy = labelId;
      if (this.currentSidebar == "全部") {
        labelIdCopy = "";
      }
      let data = {
        page: this.pageNum,
        key: this.searchValue,
        // meunIds: this.meunIds,
        pageUuid: this.pageUuid,
        sort: this.sort,
        labelId: labelIdCopy ? labelIdCopy : "",
        // searchType: 0,
        sortType:
          this.selectTheme == 5 || this.selectTheme == 6
            ? this.sortType == "authoritative"
              ? this.sortType
              : "create_time"
            : this.sortType,
        // mapId: "",
        findField: this.selectTheme,
      };

      let res =
        this.selectTheme == 5 || this.selectTheme == 6
          ? await analysisSearch(qs.stringify(data))
          : await queryOfficicalWord(qs.stringify(data));

      if (res.code == 200) {
        if (!res.data) {
          this.flag = true;
          this.infoList = [];
          return;
        }
        this.flag = false;
        this.infoList = res.data.data;
        this.totalNum = res.data.totalNum ? res.data.totalNum : res.data.total;
        this.pageUuid = res.data.page_uuid;
        this.pageCount = Math.ceil(res.data.totalNum / 11);
        if (res.data.data.length == 0) {
          this.flag = true;
        }

        // 手动添加 ischecked判断是否选中
        this.infoList.map((item) => {
          item.ischecked = item.isAssembly;
        });
      } else {
        this.$message.error(res.message);
      }
    },
    //获取菜单列表
    async getMenuList(labelId) {
      const form = new FormData();
      form.append("labelId", labelId ? labelId : "");
      form.append("parentId", 7);
      const res = await getlibrarylist(form);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      // this.collect = res.data.splice(0, 1);
      this.btnArr = res.data;

      let arr = [];
      this.btnArr.forEach((item) => {
        arr.push(String(item.id));
      });
      this.openList = arr;
    },
    //筛选排序
    handleClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.title == "时间") {
        if (item.sort) {
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "time_sort";
        } else {
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "time_sort";
        }
      }
      if (item.title == "权威") {
        if (item.sort) {
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "authoritative";
        } else {
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "authoritative";
        }
      }
      if (item.title == "热度") {
        if (item.sort) {
          this.pageNum = 1;
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "hot";
        } else {
          this.pageNum = 1;
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "hot";
        }
      }
      this.pageNum = 1;
      this.pageUuid = "";
      if (this.isCollect) {
        this.collectlist();
      } else {
        this.getList(this.labelId);
      }
    },
    //选择页数
    currentChange(i) {
      this.$nextTick(() => {
        let PageId = document.querySelector("#infocompileIndex");

        PageId.scrollTop = 0;
      });
      this.checked = false;
      this.pageNum = i;
      if (this.isCollect) {
        this.collectlist();
      } else {
        window.document.documentElement.scrollTop = 0;
        this.getList(this.labelId);
      }
    },
    // 全选
    handleCheckAllChange(value) {
      this.checkarr = [];
      if (value) {
        this.infoList.map((item) => {
          item.ischecked = true;
          this.checkarr.push(item.textMd5);
        });
      } else {
        this.infoList.map((item) => {
          if (!item.isAssembly) {
            item.ischecked = false;
          }
        });
        this.checkarr = [];
      }
    },
    // 标准版单选
    handleChecked(item) {
      if (item.isAssembly) {
        return;
      }
      item.ischecked = !item.ischecked;
      if (item.ischecked) {
        this.checkarr.push(item.textMd5);
      } else {
        this.checkarr.pop();
      }
      this.$forceUpdate();
    },
    // 添加汇编
    async addAssembly() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.checkarr.length == 0) {
        this.$message({
          type: "error",
          message: "请选择文章！",
        });
        return;
      }
      let data = [];
      if (this.labelId == null || this.labelId == "undefined") {
        this.labelId = "WKlingdrssx00001";
      }

      this.checkarr.forEach((item) => {
        let obj = {};
        obj.docId = item;
        obj.docType = 1;
        // obj.labelId = this.stair ? this.stair : this.labelId;
        data.push(obj);
      });
      const res = await addAssemblys({
        docInfoDTOList: data,
        labelId: this.stair ? this.stair : this.labelId,
      });
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: `${res.message}，您还可以添加 ${res.data} 篇文章`,
        });
        this.checkarr = [];
        this.getAssemblyNum();
        this.getList(this.labelId);
      } else {
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    showAbn() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.compilations = true;
    },
    goWorksRank() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "WorksRank",
        params: {
          index: "1",
        },
      });
    },
    goMyWork() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push("/copyrightIncome");
    },
    goAuthorRank() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "AuthorRank",
        params: {
          index: "2",
        },
      });
    },
    goMyAttention() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "MyAttention",
        params: {
          index: "4",
        },
      });
    },

    memberRenewal(item, event) {
      // this.$router.push("/VIPcenter");
      this.$store.commit("setLockClickText", item);
      this.$bus.$emit("buyTipsLock");
    },
    //点击汇编成册,弹窗
    addCompileVolume() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.isPlaying != 0) {
        return this.$message.error(
          "当前正在执行汇编成册 等待转换完成之后即可开始下一次汇编"
        );
      }
      if (this.assemblyNum == 0) {
        return this.$message("当前暂无文件可汇编成册");
      }
      this.compileVolumeDialog = true;
    },
    //执行汇编成册
    async handleCompileVolume() {
      this.$store.commit("playLifeLoading", true);
      try {
        // console.log(11);
        const res = await writerMergeWord();
        // console.log(11);
        this.$store.commit("playLifeLoading", false);
        if (res.code == 200) {
          this.progressTimer = setInterval(() => {
            if (this.progress > 80) {
              clearInterval(this.progressTimer);
            }
            let num = Math.floor(Math.random() * (15 - 10 + 1) + 5);
            this.progress += num;
          }, 2000);
          this.isPlaying++;
          this.timer = setInterval(async () => {
            this.isPlaying++;
            if (this.isPlaying > 6) {
              this.isPlaying = 0;
              clearInterval(this.timer);
            }
            let form = new FormData();
            form.append("recordId", res.data);
            const result = await checkAssembly(form);
            if (result.data.data.status == 1 || result.data.data.status == 2) {
              this.$message({
                type: "success",
                message: "汇编成功",
              });
              this.$refs["record"].initList();

              this.progress = 100;
              setTimeout(() => {
                this.progress = 0;
              }, 5000);
              clearInterval(this.timer);
              this.isPlaying = 0;
            }
          }, 5000);
          this.$message({
            type: "success",
            message: "开始汇编",
          });
          this.compileVolumeDialog = false;
        } else if (res.code == 2012) {
          this.$store.commit("playLifeLoading", false);
          this.$message({
            type: "error",
            message: res.message,
          });
          this.dataType = 4;
          this.Tipsmessage = res.message;
          this.$refs["idiomTips"].getUserinfo();
          this.vouchersNum = res.data;
          let res1 = await settings();
          if (res1.data.aiImg == 0) {
            this.isShow = true;
          } else {
            await this.$refs["idiomTips"].ticketPay();
            // this.$message.success("快捷支付成语券成功");
          }
        } else {
          this.$store.commit("playLifeLoading", false);
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (e) {
        this.$store.commit("playLifeLoading", false);
        this.$message.error("转换失败");
        this.progress = 0;
        this.isPlaying = 0;
      }
    },
    // 获取汇编成册数量
    async getAssemblyNum() {
      const res = await getAssemblyNums();
      if (res.code === 200) {
        this.assemblyNum = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //清空汇编
    cleanCompile() {
      if (this.selectList.length == 0) {
        this.$message.error("当前文件列表为空");
        return;
      }
      this.$confirm("确定要清空汇编文件嘛, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$store.commit("playLifeLoading", true);
        const res = await delDocTempInfo();
        this.$store.commit("playLifeLoading", false);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "清空成功",
          });
          this.initSelectList();
          this.getAssemblyNum();
          this.checked = false;
          this.getList(this.labelId);
          this.compileVolumeDialog = false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 购物车跳转
    shoppCar(id) {
      try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        } else {
          access_token = process.env.VUE_APP_DEFAULT_TOKEN;
        }
        // eslint-disable-next-line
        OpenBrowser(
          `https://www.writemall.com/details/${id}?token=${access_token}`
        );
        // window.location.href = `http://localhost:8081/details/${id}?token=${access_token}`
      } catch (error) {
        console.log(error);
      }
      window.isifHaveBuyDialog();
    },
    // 上传文件
    async importChecking(files) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const { file } = files;
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "doc" && suffix != "docx") {
        this.$message({
          type: "error",
          message: "请选择docx或doc文档",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      var fromData = new FormData();
      fromData.append("type", "1");
      fromData.append("file", file);
      const res = await saveToCollect(fromData);
      if (res.code == 200) {
        this.uploadSuccess = true;
      }
    },
    goDisk() {
      this.uploadSuccess = false;
      this.$store.commit("ChangeSideBarId", 77);
      this.$router.push("/disk");
      this.$bus.$emit("changeNavIndex", "智能网盘");
    },
    // 单文件下载
    async singleDownload(item, flag) {
      console.log(item);
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }

      if (flag == 1) {
        this.itemInfo = item;
      }

      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      const res = await findTextUrl({
        textMd5: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      });
      loading.close();
      if (res.code == 200) {
        const suffix = res.data.substr(res.data.lastIndexOf("."));

        // window.open(res.data);
        if (item.userId == 0) {
          const link = document.createElement("a");
          link.href = res.data;
          link.download = "download";
          link.click();
        } else {
          window.open(res.data + "?attname=" + item.footNotes + ".docx");
        }
      } else if (res.code == 8) {
        this.$message({
          type: "error",
          message: res.message,
        });
        this.clickPreview(item);
      } else if (res.code == 2012) {
        // this.$message({
        //   type: "error",
        //   message: res.message,
        // });
        this.dataType = 5;
        this.Tipsmessage = res.message;
        this.vouchersNum = res.data;
        this.$refs["idiomTips"].getUserinfo();
        let res1 = await settings();
        console.log(res1);
        if (res1.data.aiImg == 0) {
          this.isShow = true;
        } else {
          // console.log("---code---");
          await this.$refs["idiomTips"].ticketPay();
        }
      } else if (res.code == 9008) {
        this.Tipsmessage = res.message;
        this.isShow = true;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        return;
      }

      // const url = "http://fafs.antiplagiarize.com/";
      // window.location.href = `${url}${item.fileUrl}`;
    },
    //批量下载打包zip下载
    async batchDownload() {
      if (this.checkarr.length == 0) {
        this.$message({
          type: "error",
          message: "请选择文件！",
        });
        return;
      }
      this.$store.commit("playLifeLoading", true);
      let data = {};
      let arr = [];
      this.checkarr.forEach((item) => {
        let obj = {};
        obj.docId = item;
        obj.docType = 1; // 1是标准版  2是溯源版
        arr.push(obj);
        data.docInfoDTOList = arr;
      });
      const res = await fileDownload(data);
      if (res) {
        const blob = new Blob([res], {
          type: "application/zip",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", "批量下载文档.zip");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
      this.$store.commit("playLifeLoading", false);
    },
    // 获取网盘列表
    async moveTo(checkData, item) {
      this.md5 = item.textMd5;

      this.checkData = checkData;
      this.movePopup = true;
      const data = {
        id: 0,
      };
      const res = await directory(data);
      this.TreeData = res.data;
    },
    handleNodeClick(data) {
      this.moveid = data.id;
    },
    // 转存网盘确定
    async handleSure() {
      const formData = new FormData();
      formData.append("textMd5", this.md5);
      formData.append("id", this.moveid);
      const res = await saveNetdisk(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "转存成功！",
        });
        this.movePopup = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    test(node, data) {
      switch (data.filetype) {
        case 0:
          if (data.isDeleted == -1) {
            data.picture = "01";
          } else {
            data.picture = "03";
          }
          break;
      }
    },
    selectRow(select, row) {
      console.log(select, row);
      this.tableSelectList = select;
    },
    async deleteSelectAll() {
      if (this.tableSelectList.length == 0) {
        return this.$message.error("请选择需要删除的选项");
      }
      const textMd5Str = this.tableSelectList
        .map((item) => {
          return item.textMd5;
        })
        .join(",");
      const data = {
        ids: textMd5Str,
      };
      const res = await delAssembleByIds(qs.stringify(data));
      this.tableSelectList = [];
      this.$message.success("删除成功");
      this.selectPage = 1;
      this.initSelectList();
      this.getAssemblyNum();
      this.pageUuid = "";
      this.getList(this.labelId);
    },
    async deleteSelectFile(row) {
      const data = {
        ids: row.textMd5,
      };
      const res = await delAssembleByIds(qs.stringify(data));

      this.$message.success("删除成功");
      this.selectPage = 1;
      this.initSelectList();
      this.getAssemblyNum();
      this.pageUuid = "";
      this.getList(this.labelId);
    },
    selectCurrentChange(val) {
      this.selectPage = val;
      this.initSelectList();
    },
    async initSelectList() {
      const data = {
        page: this.selectPage,
        limit: 10,
      };
      this.dialogLoading = true;
      const res = await queryAssembleWord(data);
      this.dialogLoading = false;
      this.selectList = res.data.data;
      this.selectTotal = res.data.total;
    },

    async openArticleClick(openArticleClick) {
      const data = {
        md5Label: openArticleClick,
        originalId: 0,
        templateId: "",
      };

      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });

      const resdata = await openLibraryFile(qs.stringify(data));
      loading.close();

      if (resdata.code != 200) {
        this.$message.error(resdata.message);
        return;
      }
      this.$refs["dataAnalysisDetails"].openLibraryFile = {
        ...resdata.data.obj,
        html: resdata.data.html,
        payStatus: resdata.data.status,
        url: resdata.data.url,
        viptip: resdata.data.viptip,
        // author: resdata.data.author,
        originalId: 0,
        md5Label: openArticleClick,
        // traceSourceDate: item.traceSourceDate,
        // traceSourceEvaluation: item.traceSourceEvaluation,
        // isTraceSource: item.isTraceSource,
        // currentClickItemVip: this.currentClickItemVip,
      };
      window.document.documentElement.scrollTop = 0;
      this.$refs["dataAnalysisDetails"].openLibraryFile.author =
        resdata.data.author;
      this.isDetails = true;
    },

    getHref() {
      let url = window.location.search;
      let theRequest = new Object();

      if (url.includes("token=")) {
        let str = url.substr(1);

        let strs = str.split("&");

        // console.log(strs);

        strs.forEach((item, i) => {
          theRequest[item.split("=")[0]] = item.split("=")[1];
        });

        if (theRequest.key) {
          this.searchValue = decodeURI(theRequest.key);
          this.search();
        }
      }

      //   console.log(theRequest);
    },
  },
  created() {
    if (this.$route.query.key) {
      this.searchValue = this.$route.query.key;
      this.selectTheme = "2";
      this.findField = 2;
      this.search();
    } else {
      if (!this.$route.params.labelId) {
        this.getList();
      }
    }
    if (!this.$route.params.labelId) {
      this.getMenuList();
    }

    this.getAssemblyNum();
  },
  mounted() {
    this.getHref();
    if (this.$route.params?.labelId) {
      this.sidebarTtemId = this.$route.params.labelId;
      this.currentSidebar = "";
      this.getList(this.sidebarTtemId);
      // if (this.$route.params.labelId.includes("-")) {
      //   this.stairCopy = this.$route.params.labelId.split("-")[0];
      // } else {
      //   this.stairCopy = this.$route.params.labelId;
      // }

      this.getMenuList(this.sidebarTtemId);

      // this.$refs.elMenu.openedMenus.push(this.sidebarTtemId);
    } else {
      this.$refs.elMenu.activeIndex = "-2";
    }

    if (this.$route.params?.searchValue) {
      this.searchValue = this.$route.params?.searchValue;
      this.getList();
    }
    if (this.$route.params?.articleClick) {
      this.openArticleClick(this.$route.params?.articleClick);
    }
  },
};
</script>
<style lang="scss" scoped>
.el-tooltip__popper {
  background: #fff !important;
  color: #ff6900 !important;
  border: 1px solid #ff6900 !important;
}

.el-badge__content.is-fixed {
  top: 0px !important;
  right: 0px !important;
}
</style>
<style lang="scss" scoped>
.libraryS {
  .selectList {
    ::v-deep .el-dialog {
      height: 600px;
      overflow: auto;
    }
  }
  .pitchOn {
    ::v-deep .el-submenu__title {
      box-sizing: border-box;
      border-right: #ff6900 4px solid;
    }
  }
  .aa {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 50px;
  }
  .compilations {
    ::v-deep .el-dialog {
      height: 600px;
      overflow: auto;
      .el-dialog__body {
        padding: 0px;
        .progress {
          height: 3px;
          background-color: #4587ff;
          transition: all 0.1s;
        }
        .option {
          padding: 10px 20px;
        }
      }
    }
  }
  .box {
    display: flex;
    min-width: 800px;
    max-width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      width: 936px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      position: relative;

      .perch {
        // flex: 1;
        min-width: 190px;
        height: calc(100vh);
      }

      .mask_layer {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: calc(100%);
        opacity: 0.2;
        background: #000;
        z-index: 2000;
      }

      .sidebar_box {
        position: absolute;
        left: 0;
        background-color: #fff;
        z-index: 2000;
        display: flex;

        .an_btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          z-index: 10000;
          background-color: #ff6900;
          color: #fff;
          transform: translate(-50%);
          right: -40px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.2);
        }

        .spin {
          transform: translate(-50%) rotate(180deg);
        }

        .absolute {
          background-color: #fff;
          position: absolute;
          left: 0;
          top: 0px;
        }

        .sidebar {
          width: 210px;
          flex: 1;
          height: calc(100vh);
          overflow-y: auto;
          z-index: 2001;
          background-color: #fff;

          .el-menu {
            border-right: 0px;
            .isSubsetClass {
              ::v-deep .el-submenu__title {
                // cursor: not-allowed;
                // color: #ccc;
                .el-submenu__icon-arrow {
                  display: none;
                }
              }
            }

            .charNumClass {
              ::v-deep .el-submenu__title {
                cursor: not-allowed;
                color: #ccc;
              }
              cursor: not-allowed;
              color: #ccc;
            }
            .submenu {
              ::v-deep .el-submenu__title {
                cursor: not-allowed;
                color: #ccc;
                .el-submenu__icon-arrow {
                  display: none;
                }
              }
            }

            .elMenuItem {
              cursor: not-allowed;
              color: #ccc;
            }
            .el-menu-item {
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              -webkit-transition: none;
              transition: none;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              align-items: center;
              white-space: nowrap;
              min-width: 190px;
              width: 190px;
              .el-icon-arrow-left {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0px;
              }
              &:hover {
                color: #ff6900;
                border-right: #ff6900 4px solid;
                background-color: #ffbf6914 !important;
              }
            }
            .el-menu-item.is-active {
              border-right: #ff6900 4px solid;
              background-color: #ffbf6914;
            }
          }
          ::v-deep .el-submenu {
            .el-submenu__title {
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              align-items: center;
              white-space: nowrap;
              .vipIcon {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 40px;
                margin-left: 0px;
              }
              img {
                width: 13px;

                margin-left: 10px;
              }
              &:hover {
                color: #ff6900;
                background-color: #ffbf6914 !important;
              }
            }
            .el-menu {
              .is-active {
                border-right: #ff6900 4px solid;
              }
            }
          }

          .img1 {
            width: 20px;
            margin-right: 10px;
          }
          .img2 {
            width: 20px;
            margin-left: 10px;
          }
          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }
        }
      }

      ::v-deep .el-button {
        height: 30px;
        background: #f3f4f6;
        border: none;
        color: #ff6900;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        padding: 0px;
        box-sizing: border-box;
        width: 56px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .color {
        color: #ff5500 !important;
      }

      .eltree {
        margin-top: -10px;
        width: 310px;
        height: 450px;
        overflow: auto;
      }

      .infoMian {
        position: fixed;
        z-index: 20;
        top: 50%;
        left: 50%;
        width: 310px;
        height: 500px;
        background-color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        font-family: Microsoft YaHei;
        transform: translate(-50%, -50%);
        border: 0px solid #818790;
        box-shadow: 0px 3px 15px 15px rgba(98, 102, 107, 0.16);
        border-radius: 5px;

        .title {
          margin-top: 10px;
          margin-left: 20px;
          color: #333;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
        }

        .xian {
          width: 280px;
          height: 10px;
          border-top: 1px solid #aaa;
          margin: 15px auto;
        }

        .cancel {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 10px;
          height: 10px;
          color: #c7cbd3;
          cursor: pointer;
        }
      }

      .item {
        font-size: 18px;
        color: #ff6900;
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
      }

      .move {
        font-size: 18px;
        animation: move 1s;
      }

      @keyframes move {
        10% {
          bottom: -5px;
        }

        30% {
          bottom: 5px;
        }

        50% {
          bottom: -5px;
        }

        70% {
          bottom: 5px;
        }

        90% {
          bottom: -5px;
        }

        100% {
          bottom: 0px;
        }
      }

      .kong {
        height: 440px;
        text-align: center;

        img {
          margin-top: 100px;
          width: 150px;
          margin-bottom: 20px;
          height: 150px;
        }

        div {
          margin-top: 10px;
          text-align: center;
          color: #999;
          font-size: 13px;
        }
      }

      // 搜索
      .search_box {
        // padding: 20px 30px 0;
        width: 90%;
        margin: 20px auto;
        height: 50px;
        .search {
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          // padding: 5px 5px 5px 0;
          height: 50px;
          border-radius: 3px;
          border: 1px solid #ff6900;

          .search_left {
            .el-select {
              ::v-deep .el-input {
                .el-input__inner {
                  width: 100px;
                  border: 0px;
                }
              }
            }
          }

          .inp {
            height: 26px;
            border-left: 1px solid #e4e4e4;
            padding-left: 20px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-gray;
            flex: 1;
          }
          .search_btn {
            // width: 40px;
            height: 100%;
            padding: 0 10px;
            background: #ff6900;
            // @include backgroundGroup("~assets/images/idiom.png");
            @include flex-center();

            cursor: pointer;
            > img:first-child {
              width: 52px;
              height: 22px;
              margin: 0 10px;
            }
            // .search_icon {
            //   width: 17px;
            //   height: 17px;
            //   @include backgroundGroup("~assets/images/idiom.jpg");
            // }
          }
        }

        .keyword_box {
          margin-top: 20px;
          display: flex;

          .keyword_item {
            font-size: 14px;
            color: #666666;
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }

      .infocompileIndex {
        height: 100%;
        position: relative;
        box-sizing: border-box;
        flex: 1;
        width: 696px;
        .banner {
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: 40px;

          // border: 1px solid #eaeef1;
          background: #fbfcfd;
          .item {
            display: flex;
            align-items: center;
            margin: 0 15px;
            font-size: 14px;
            cursor: pointer;
            color: #333;
            img {
              margin-right: 5px;
              width: 16px;
              height: 16px;
            }
          }
        }
        // 主题

        &-title {
          flex-wrap: wrap;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .infocompileIndex-title-item {
            cursor: pointer;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 400;
            width: 105px;
            text-align: center;
            color: $color-text-gray-s;
          }

          .infocompileIndex-title-item:hover {
            color: $color-text-active;
          }
        }

        &-content {
          width: 100%;
          margin: 20px auto 0;
          .theme_box {
            padding: 10px 0 0;
            .title {
              font-weight: bold;
            }
          }

          &-breadcrumb {
            margin-top: 20px;
            display: flex;

            .infocompileIndex-content-breadcrumb-item {
              display: flex;
              height: 30px;
              background: #f3f4f6;
              border-radius: 15px;
              align-items: center;
              margin-left: 20px;
              cursor: pointer;

              span {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #ff6900;
              }

              .item-title {
                margin-left: 15px;
                width: 56px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .item-num {
                margin-left: 5px;
              }

              i {
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }

          &-list {
            margin-top: 10px;

            &-title {
              min-width: 550px;
              padding: 0 30px;
              display: flex;
              align-items: center;

              &-left {
                display: flex;
                align-items: center;
                margin-left: 10px;

                .left-item {
                  display: flex;
                  margin-right: 20px;
                  cursor: pointer;

                  ::v-deep .el-badge__content.is-fixed {
                    top: 50%;
                    right: 0px;
                  }

                  .left-item-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                  }

                  .icon {
                    @include backgroundGroup(
                      "~assets/images/infoCompile-icon.png"
                    );
                  }

                  .add {
                    @include backgroundGroup(
                      "~assets/images/infoCompile-add.png"
                    );
                  }

                  font-size: 14px;

                  .download {
                    @include backgroundGroup(
                      "~assets/images/infoCompile-download.png"
                    );
                  }
                  .download {
                    margin-left: 20px;
                    @include backgroundGroup("~assets/images/upload-gray.png");
                  }
                  .folder {
                    @include backgroundGroup("~assets/images/folder.png");
                  }
                }
              }

              ::v-deep .el-checkbox {
                .el-checkbox__inner {
                  width: 15px;
                  height: 15px;
                  border-color: #ff6900;
                }

                .el-checkbox__label {
                  font-size: $font-size-medium;

                  font-weight: 500;
                  color: $color-text-black;
                }
              }
              &-right {
                display: flex;
                align-items: center;
                padding: 0 20px;

                .right-item {
                  display: flex;
                  margin-right: 20px;
                  cursor: pointer;

                  .right-item-text {
                    font-size: $font-size-medium;

                    font-weight: 500;
                    color: $color-text-black;
                  }

                  .right-item-icon {
                    width: 9px;
                    height: 13px;
                    margin-left: 6px;
                  }

                  .up {
                    @include backgroundGroup(
                      "~assets/images/goldenSentence/sortIcon-blue-up.png"
                    );
                  }

                  .down {
                    @include backgroundGroup(
                      "~assets/images/goldenSentence/sortIcon-blue.png"
                    );
                  }

                  .fontColor {
                    color: #ff6900;
                  }
                }

                .right-item:last-of-type {
                  margin-right: 0px;
                }
              }
            }

            &-content {
              margin-top: 20px;
              padding: 0 10px;

              .content-item {
                min-width: 550px;
                position: relative;
                .source_version {
                  position: absolute;
                  top: 20px;
                  right: 5%;
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  z-index: 99;
                  cursor: pointer;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                }
                .content-item-icon {
                  position: absolute;
                  left: 0px;
                  top: 10px;
                  width: 34px;
                  height: 30px;
                  display: none;
                }

                .reprint {
                  @include backgroundGroup("~assets/images/info/reprint.png");
                }

                .starting {
                  @include backgroundGroup("~assets/images/info/starting.png");
                }

                .content-item-like {
                  width: 17px;
                  height: 16px;
                  cursor: pointer;
                  margin-right: 10px;
                }

                .like {
                  @include backgroundGroup("~assets/images/info/like.png");
                }

                .like-red {
                  @include backgroundGroup("~assets/images/info/like-red.png");
                }

                .content-item-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #000000;
                  line-height: 26px;
                  padding: 20px;

                  // span:last-of-type {
                  //   color: #ff6900;
                  //   cursor: pointer;
                  //   text-decoration: underline;
                  // }

                  .fontBlue {
                    color: #ff6900;
                  }
                }

                .title {
                  font-size: 14px;
                  padding: 20px 20px 0;
                  display: flex;
                  align-items: center;
                  .introduce-checkBox {
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .introduce-checkBox-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/checkBox.png");
                      margin-right: 5px;
                    }

                    .checkBox-checked {
                      background-image: url("~assets/images/info/checkBox-checked.png");
                    }
                  }

                  .title_box {
                    flex: 1;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 150px;
                    &:hover {
                      color: #ff6900;
                    }
                  }
                }

                .content-item-introduce {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  padding: 0 20px 20px;
                  border-bottom: 1px solid #efefef;

                  .introduce-radioBox {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .introduce-radioBox-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/radio.png");
                      margin-right: 4px;
                    }

                    .radioBox-checked {
                      background-image: url("~assets/images/info/radio-checked.png");
                    }
                  }

                  .introduce-starBox {
                    margin-right: 15px;
                    display: flex;
                    align-items: center;

                    .introduce-starBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/star.png");
                      margin-right: 5px;
                    }
                  }

                  .introduce-hotBox {
                    margin-left: 18px;
                    display: flex;
                    align-items: center;

                    .introduce-hotBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/hot.png");
                      margin-right: 5px;
                    }
                  }

                  .introduce-lampBox {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    .introduce-lampBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/lamp.png");
                      margin-right: 4px;
                    }
                  }

                  .introduce-timeBox {
                    display: flex;
                    align-items: center;

                    .introduce-timeBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url("~assets/images/info/time.png");
                      margin-right: 4px;
                    }

                    .introduce-timeBox-text {
                      width: 85px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  .introduce-titleBox-text {
                    width: 120px;
                    margin-left: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .content-item-btnGroup {
                  display: flex;
                  align-items: center;

                  flex: 1;
                  justify-content: space-around;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;

                  .btnGroup-line {
                    width: 1px;
                    height: 30px;
                    background: #d8d8d8;
                  }

                  .content-item-btnItem {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: 3px;
                    }

                    .content-item-btnItem-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      margin-right: 5px;
                    }

                    .download {
                      background-image: url("~assets/images/info/download.png");
                    }

                    .upload {
                      background-image: url("~assets/images/info/upload.png");
                    }

                    .relation {
                      background-image: url("~assets/images/info/relation.png");
                    }

                    .index {
                      background-image: url("~assets/images/info/index.png");
                    }

                    .copyright {
                      background-image: url("~assets/images/info/copyright.png");
                    }

                    .share {
                      background-image: url("~assets/images/info/share.png");
                    }
                  }

                  .downloadBox:hover .download {
                    background-image: url("~assets/images/info/download-red.png");
                  }

                  .uploadBox:hover .upload {
                    background-image: url("~assets/images/info/upload-red.png");
                  }

                  .relationBox:hover .relation {
                    background-image: url("~assets/images/info/relation-red.png");
                  }

                  .indexBox:hover .index {
                    background-image: url("~assets/images/info/index-red.png");
                  }

                  .copyrightBox:hover .copyright {
                    background-image: url("~assets/images/info/copyright-red.png");
                  }

                  .shareBox:hover .share {
                    background-image: url("~assets/images/info/share-red.png");
                  }

                  .content-item-btnItem:hover .content-item-btnItem-text {
                    color: #ec5e58;
                  }
                }
              }

              .content-item:hover {
                background: #ffffff;
                box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
                border-radius: 5px;
              }
            }
          }
        }

        &-footer {
          @include noselect;
          margin: 30px 0;
          display: flex;
          justify-content: center;

          ::v-deep .el-pagination {
            button {
              width: 80px;
            }
          }
        }

        .addFil {
          ::v-deep .el-dialog {
            .el-dialog__footer {
              .dialog-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .footer-btn {
                  cursor: pointer;
                  width: 71px;
                  height: 41px;
                  border: 1px solid #cecece;
                  border-radius: 3px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  background: #fff;
                }

                .sure {
                  margin-right: 12px;
                  background: #ff6900;
                  color: #fff;
                }
              }
            }
          }
        }
        .compile-volume {
          ::v-deep .el-dialog {
            @include flex-center(column);
            justify-content: normal;
            align-items: normal;
            margin-top: 20vh !important;
            width: 340px;
            height: 200px;
            background: #ffffff;
            box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
            .el-dialog__header {
              padding: 12px 12px 0px;
              .el-dialog__title {
                font-size: $font-size-small;
                font-family: PingFang SC;
                font-weight: 400;
                color: $color-text-gray;
              }
              .el-dialog__headerbtn {
                top: 12px;
                right: 12px;
              }
            }
            .el-dialog__body {
              display: flex;
              flex-direction: column;
              flex: 1;
              // padding: 35px 30;
              // width: 75%;
              .content {
                @include flex-center;
                flex: 1;
              }
              .footer {
                display: flex;
                justify-content: space-evenly;
                margin-top: 28px;
                padding: 20px 0;
                width: 100%;
                .footer-btn {
                  @include noselect;
                  font-size: $font-size-medium;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: $color-bg-heightlight;
                  cursor: pointer;
                  &.clear {
                    color: #999999;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right {
      background-color: #fff;
      flex: 1;
    }
  }

  .dataAnalysisDetails {
    // position: absolute;
    left: 0;
    top: 0px;
    // height: 100%;
    z-index: 2003;
    background: #fff;
    width: 100%;
  }
  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
