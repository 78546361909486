var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"record"},[(_vm.tableData.length != 0)?_c('div',{staticClass:"table-list"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"width":"50","label":"序号","type":"index","align":"center"}}),_c('el-table-column',{attrs:{"prop":"createtime","align":"center","label":"时间"}}),_c('el-table-column',{attrs:{"prop":"num","align":"center","label":"汇编数量"}}),_c('el-table-column',{attrs:{"align":"center","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:[
              'states',
              scope.row.status === 0
                ? 'gray'
                : scope.row.status === 1
                ? 'green'
                : 'red' ]},[_vm._v(" "+_vm._s(scope.row.status === 0 ? "转换中" : scope.row.status === 1 ? "转换完成" : "转换失败")+" ")])]}}],null,false,2788351273)}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1)?_c('img',{staticStyle:{"vertical-align":"middle","cursor":"pointer"},attrs:{"src":scope.row.type == 1
                ? require('assets/images/view-kind.png')
                : require('assets/images/view-kind.png'),"alt":""},on:{"click":function($event){return _vm.handleClick(scope.row)}}}):_vm._e(),(scope.row.status == 1)?_c('span',{staticStyle:{"color":"#4587ff","cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(scope.row)}}},[_vm._v(_vm._s(scope.row.type == 1 ? "查看" : "查看"))]):_vm._e()]}}],null,false,1227676028)})],1),_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":_vm.limit,"pager-count":5,"layout":"total, prev, pager, next, jumper","total":_vm.pageTotal},on:{"current-change":_vm.currentChange}})],1):_c('div',{staticClass:"kong"},[_c('img',{attrs:{"src":require('assets/images/kong.png'),"alt":""}}),_c('div',[_vm._v("暂无汇编记录")])])])}
var staticRenderFns = []

export { render, staticRenderFns }